export default {
  computed: {
    fileBtnOptions(){
      let result = [];
      if (['add', 'edit'].includes(this.formType)) {
        result.push({
          label: "上传",
          emit: "headImport",
          type: "button",
          btnOptType: "upload",
        });
      }
      return result
    },
    userBtnOptions(){
      let result = [];
      if (['add', 'edit'].includes(this.formType)) {
        result.push({
          label: "选择",
          emit: "head-add",
          type: "button",
          btnOptType: "headSelect",
        });
        // result.push({
        //   label: "移除",
        //   emit: "head-remove",
        //   type: "button",
        //   btnOptType: "remove",
        // });
      }
      return result
    },
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.formType)) {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
        // result.push({
        //   label: "引用",
        //   emit: "head-quote",
        //   type: "button",
        //   btnOptType: "quote",
        // });
      }
      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
    jcxflOption() {
      return {
        span:24,
        submitBtn:(this.jcxflForm.isObject == 1 && this.jcxflForm.objectType == 'SD') || this.jcxflForm.id ? true : this.jcxflForm.isObject == 0 ? true : false ,
        emptyText:'取消',
        column: [
          {
            span:24,
            labelWidth:200,
            label: '类型',
            prop: 'isObject',
            type: 'radio',
            value: this.jcxflForm.isObject == 1  ? 1:0,
            dicData:[{
              label:'检查对象分类',
              value:0
            },{
              label:'检查对象',
              value:1
            }]
          },
          {
            span:24,
            props:{
              children: "children",
              label: "typeName",
              value:'id',
            },
            dicData:this.corePersonnelTreeData,
            type: 'tree',
            labelWidth:200,
            clearable:true,
            label: "上级检查项分类",
            prop: "parentId",
          },
          {
            span:24,
            display:this.jcxflForm.isObject == 0,
            labelWidth:200,
            label: "分类名称",
            prop: "typeName",
            rules: [{
              required: true,
              message: "请输入分类名称",
              trigger: "blur"
            }]
          },
          {
            span:12,
            disabled:this.jcxflForm.id ?true :false,
            display:this.jcxflForm.isObject == 1,
            labelWidth:200,
            label: "对象类型",
            type: 'select',
            change:()=>{
              if(this.jcxflForm.id)return
              this.jcxflForm.typeName=''
          },
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=HD_CHECK_OBJ_TYPE',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            prop: "objectType",
            rules: [{
              required: true,
              message: "请选择对象类型",
              trigger: "blur"
            }]
          },
          {
            span:12,
            formSlot:true,
            display:this.jcxflForm.isObject == 1,
            labelWidth:this.jcxflForm.objectType == 'SD'  ?100 : 0,
            label: this.jcxflForm.objectType == 'SD' ?"对象名称" :'',
            prop: "typeName",
            rules: [{
              required:this.jcxflForm.objectType == 'SD' ? true : false,
              message: "请输入对象名称",
              trigger: "blur"
            }]
          },

        ]
      }
     },
     jcxOption(){
      let that=this
      return {
        span:24,
        labelWidth:100,
        emptyText:'取消',
        column: [
          {
            span:12,
            label: "检查人",
            prop: "respNickName",
            // type:'select',
            // dicUrl:'/api/sinoma-hse-prj/hdcheckplanperson/list?personType=leader&planId='+this.dataForm.id,
            // change({column,value}){
             
            //   if(!value){
            //     that.$set(that.jcxForm, 'respUserName', '')
            //     that.$set(that.jcxForm, 'respNickName', '')
            //   }else {
            //     console.log(value,that.thirdPartyOrganizationTableData);
            //     let {userName,nickName,userAccount}=that.thirdPartyOrganizationTableData.filter(item=>item.userAccount == value)[0]
            //     that.$set(that.jcxForm, 'respUserName', userName)
            //     that.$set(that.jcxForm, 'respNickName', nickName)
            //     that.$set(that.jcxForm, 'respUserAccount', userAccount)
            //   }
            // },
            // props: {
            //   label: 'nickName',
            //   value: 'userName',
            // },
            disabled: true
          },
          {
            span:12,
            value:'1',
            disabled:true,
            label: "检查对象",
            prop: "objectName",
            rules: [{
              required: true,
              message: "请输入检查对象",
              trigger: "blur"
            }]
          },
          {
            label: "检查内容",
            prop: "indexName",
            type:'textarea',
            maxlength:'64',
            showWordLimit:true,
            minRows:3,
            rules: [{
              required: true,
              message: "请输入检查内容",
              trigger: "blur"
            }]
          }
        ]
      }
    } ,
    cyryBtnOptions(){
      let result = [];
      if (['add', 'edit'].includes(this.formType)) {
        result.push({
          label: "新增",
          emit: "head-add",
          type: "button",
          btnOptType: "add",
        });
        result.push({
          label: "移除",
          emit: "head-remove",
          type: "button",
          btnOptType: "remove",
        });
      }
      return result
    },
  },
  data(){
    let that=this;
    return {
      // jcxOption: {
      //   span:24,
      //   emptyText:'取消',
      //   column: [
      //     // {
      //     //   span:12,
      //     //   label: "负责人",
      //     //   prop: "respNickName",
      //     //   click:()=>{
      //     //     this.headAdd('respUserName')
      //     //   },
      //     //   // rules: [{
      //     //   //   required: true,
      //     //   //   message: "请选择输入负责人",
      //     //   //   trigger: "blur"
      //     //   // }]
      //     // },
      //     {
      //       span:12,
      //       label: "负责人",
      //       prop: "respUserName",
      //       type:'select',
      //       dicData:this.thirdPartyOrganizationTableData,
      //       change({column,value}){
      //         if(!value){
      //           that.$set(that.jcxForm, 'respUserName', '')
      //           that.$set(that.jcxForm, 'respNickName', '')
      //         }else {
      //           let {userName,userNickName,userAccount}=that.thirdPartyOrganizationTableData.filter(item=>item.userName == value)[0]
      //           that.$set(that.jcxForm, 'respUserName', userName)
      //           that.$set(that.jcxForm, 'respNickName', userNickName)
      //           that.$set(that.jcxForm, 'respUserAccount', userAccount)
      //         }
      //       },
      //       props: {
      //         label: 'userNickName',
      //         value: 'userName',
      //       },

      //     },
      //     {
      //       span:12,
      //       value:'1',
      //       disabled:true,
      //       label: "检查对象",
      //       prop: "objectName"
      //     },
      //     {
      //       label: "检查内容",
      //       prop: "indexName",
      //       type:'textarea',
      //       maxlength:'64',
      //       showWordLimit:true,
      //       minRows:3,
      //       rules: [{
      //         required: true,
      //         message: "请输入检查内容",
      //         trigger: "blur"
      //       }]
      //     }
      //   ]
      // },
      // jcxflOption: {
      //   span:24,
      //   emptyText:'取消',
      //   column: [
      //     {
      //       span:24,
      //       disabled:true,
      //       labelWidth:200,
      //       label: "上级检查项分类",
      //       prop: "parentName",
      //     },
      //    {
      //       span:24,
      //      labelWidth:200,
      //       label: "分类名称",
      //      prop: "typeName",
      //      maxlength:'64',
      //      showWordLimit:true,
      //      rules: [{
      //        required: true,
      //        message: "请输入分类名称",
      //        trigger: "blur"
      //      }]
      //     },

      //   ]
      // },
      corePersonnelHeadBtnOptions: [
        {label: "新增", emit: "head-add", type: "button", icon: ""},
        {label: "导入", emit: "handleImport", type: "button", icon: ""},
        {label: "导出", emit: "head-export", type: "button", icon: ""},
        // {label: "风险库", emit: "head-fx", type: "button", icon: ""},
        {label: "移除", emit: "head-remove", type: "button", icon: ""},
      ],
      formType: '',
      headTitle: '',
      mapBox: false,
      mapdata: '',
      dataForm: {
      },
      corePersonnelTableOption: {
        index: true,
        indexLabel: "序号",
        menuWidth: 100,
        column: [{
          label: "检查内容",
          prop: "indexName",
          align: "left",
          overHidden: true
        },
          {
            label: "检查人",
            prop: "respNickName",
            width: 200,
            align: "center",
            overHidden: true,
          },

        ],
      },
      tableData: [],
      corePersonnelTableData: [],
      tableLoading: false,
      jcxTableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      jcryPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      cyryPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      defaultProps: {
        children: "children",
        label: "typeName",
      },
      organizationPersonnelTreeData: [],
      searchOrTitle:"structureName",
      activeName: 'thirdPartyOrganization',
      corePersonnelTreeData: [],
      searchColumns: [
        {placeholder: '请输入风险名称', prop: "riskCode", span: 3},
        {placeholder: '请输入措施分类', prop: "riskCode", span: 3},
        {placeholder: '请输入措施描述', prop: "riskCode", span: 3},
        {placeholder: '请输入隐患排查内容', prop: "riskCode", span: 3},
        {placeholder: '请选择管控层级', prop: "riskCode", span: 3},
        {placeholder: '请输入岗位/角色', prop: "riskCode", span: 3},
        {placeholder: '请输入负责人员', prop: "riskCode", span: 3},
      ],
      QuoteSearchColumns:[
        {placeholder: '请输入检查活动编号', prop: "actCode", span: 3},
        {placeholder: '请输入检查名称', prop: "actName", span: 3},
        {
          prop: "checkType",
          span: 3,
          type: 'select',
          placeholder: '请选择检查类型',
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=safey_check_type`,
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
        {placeholder: '请选择开始结束时间', prop: "checkTimes", span: 3,type:'daterange',format:'yyyy-MM-dd'},
      ],
      hdOption: {
        index: true,
        menuWidth: 100,
        linklabel: "title",
        selection:false,
        column: [{
          label: "检查编号",
          prop: "actCode",
          align: "left",
          overHidden: true
        },
          {
            label: "检查名称",
            prop: "actName",
            width: 300,
            align: "center",
            overHidden: true
          },
          {
            label: "分类",
            prop: "checkTypeName",
            width: 200,
            align: "center",
            overHidden: true,
            slot:true,
          },
          {
            label: "创建时间",
            prop: "createTime",
            width: 200,
            align: "center",
            overHidden: true
          },
        ],
      },
      fxyOption: {
        index: true,
        indexLabel: "序号",
        menuWidth: 100,
        linklabel: "title",
        column: [{
          label: "风险名称",
          prop: "riskName",
          align: "left",
          overHidden: true
        },
          {
            label: "措施分类",
            prop: "treatmentTypeName",
            width: 200,
            align: "center",
            overHidden: true
          },
          {
            label: "措施描述",
            prop: "treatmentDesc",
            width: 200,
            align: "center",
            overHidden: true
          },
          {
            label: "隐患排查内容",
            prop: "hdCheckContext",
            width: 150,
            align: "center",
            overHidden: true
          },
          {
            label: "管控层级",
            prop: "controlLevel",
            width: 150,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=risk_control_level`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            type:'select',
            align: "center",
            overHidden: true
          },  {
            label: "岗位/角色",
            prop: "respPostName",
            width: 150,
            align: "center",
            formatter:(row)=>{
              return `${ row.respPostName+'/'}${row.respRoleName}`
            },
            overHidden: true
          }, {
            label: "负责人员",
            prop: "respUserName",
            width: 150,
            align: "center",
            overHidden: true
          },

        ],
      },
      thirdPartyOrganizationTableOption: {
        index: true,
        height:200,
        indexLabel: "序号",
        menuWidth: 100,
        linklabel: "title",
        selection: false,
        menu: false,
        column: [{
          label: "人员名称",
          prop: "nickName",
          align: "center",
          width: 200,
          overHidden: true
        },
          {
            label: "所在组织部门",
            prop: "deptName",
            
            align: "center",
            overHidden: true
          },
          // {
          //   label: "角色",
          //   prop: "roleName",
          //   width: 200,
          //   align: "center",
          //   overHidden: true
          // },
          {
            label: "岗位",
            prop: "attr1",
            width: 150,
            align: "center",
            overHidden: true
          },
        ],
      },
      thirdPartyOrganizationTableData: [],
      thirdPartyPersonnelTableData: [],
      checkJoinDataOption:{
        index: true,
        height:200,
        indexLabel: "序号",
        menuWidth: 100,
        linklabel: "title",
        column: [{
          label: "人员名称",
          prop: "nickName",
          align: "left",
          width: 200,
          overHidden: true
        },
          {
            label: "所在组织部门",
            prop: "deptName",
            align: "left",
            overHidden: true
          },
          // {
          //   label: "角色",
          //   prop: "roleName",
          //   align: "left",
          //   overHidden: true
          // },
          {
            label: "岗位",
            prop: "attr1",
            width: 150,
            align: "center",
            overHidden: true
          },
        ],
      },
      checkJoinData: [],
      checkJoinDataLoading: false,
      rules: {
        actName: [{
          required: true,
          message: '请输入检查名称',
          trigger: 'blur'
        }],
        startUserName: [{
          required: true,
          message: '请选择发起人',
          trigger: 'change'
        }],
        projectName: [{
          required: true,
          message: '请选择所属项目',
          trigger: 'change'
        }],
        checkTime: [{
          required: true,
          message: '请选择检查时间',
          trigger: 'change'
        }],

      },
      prjDomesticList: [],
      selectionList: [],
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        excelForm: {},
        column: [{
          label: '数据导入',
          prop: 'excelFile',
          type: 'upload',
          drag: true,
          loadText: this.$t("cip.plat.sys.user.field.waiting"),
          span: 24,
          propsHttp: {
            res: 'data'
          },
          tip: this.$t("cip.plat.sys.user.field.format"),
          action: "/api/sinoma-hse-prj/hdcheckplanindex/import"
        },
          {
            label: this.$t("cip.plat.sys.user.field.excelTemplate"),
            prop: 'excelTemplate',
            formslot: true,
            span: 24,
          }
        ]
      }
    }
  },
}
