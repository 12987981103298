<template>
  <div style="height: 100%; background-color: #ffffff">
    <head-layout
      head-title="检查基本信息"
      :head-btn-options="headBtnOptions"
      @head-save="headSave()"
      @head-quote="headQuote()"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
      <div class="formMain">
        <el-form
          ref="dataForm"
          :model="dataForm"
          label-width="120px"
          :disabled="formType == 'view'"
          :rules="rules"
        >
          <!-- <div class="formTopic" style="margin-bottom: 12px">检查基本信息</div> -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="配置编号" prop="planCode">
                <el-input
                  type="text"
                  v-model="dataForm.planCode"
                  placeholder="请输入配置编码"
                  maxlength="64"
                  show-word-limit
                >
                  <span slot="append">
                    自动生成
                    <el-switch
                      v-model="prjCodeAuto"
                      @change="prjCodeAutoChange"
                    ></el-switch>
                  </span>
                </el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="3">
              <el-form-item label="自动生成">
                <el-switch
                  v-model="prjCodeAuto"
                  @change="prjCodeAutoChange"
                ></el-switch>
              </el-form-item>
            </el-col> -->
            <el-col :span="8">
              <el-form-item label="配置名称" prop="planName">
                <el-input
                  v-model="dataForm.planName"
                  placeholder="请输入配置名称"
                  clearable
                  maxlength="64"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="关联项目" prop="organizationName">
                <!-- <el-select
                  v-model="companyName"
                  clearable
                  ref="selectTree"
                  @clear="clearHandle"
                  placeholder="请选择项目"
                >
                  <el-option :value="companyId" :label="companyName">
                    <el-tree
                      id="tree-option"
                      :data="projectSelection"
                      :highlight-current="true"
                      :props="companydefaultProps"
                      :accordion="true"
                      node-key="id"
                      :default-expanded-keys="defaultExpandedKey"
                      @node-click="handleNodeClickListTree"
                    >
                    </el-tree>
                  </el-option>
                </el-select> -->
                <!-- <el-select
                  v-model="projectInfo"
                  placeholder="请选择"
                  value-key="id"
                  v-if="formType != 'view'"
                  @change="projectChange"
                >
                  <el-option
                    v-for="item in projectSelection"
                    :key="item.id"
                    :label="item.title"
                    :value="item"
                  >
                  </el-option>
                </el-select> -->
                <el-input
                  v-model="dataForm.organizationName"
                  placeholder=""
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
             <!-- <el-col :span="8">
              <el-form-item label="检查组组长" prop="checkLeaderName">
                <el-input v-model="dataForm.checkLeaderName" @focus="headAdd('checkLeaderName')"
                  placeholder="请选择检查组组长"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="参与人" prop="checkJoinName">
                <el-input v-model="dataForm.checkJoinName" @focus="headAdd1('checkJoinName')"
                  placeholder="请选择参与人"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="检查类型" prop="checkType">
                <el-select
                  v-model="checkTypeItem"
                  placeholder="请选择检查类型"
                  value-key="dictKey"
                  clearable
                  @change="checkTypeObj"
                >
                  <el-option
                    v-for="item in safey_check_type"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
           
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="检查类型" prop="checkType">
                <el-select
                  v-model="checkTypeItem"
                  placeholder="请选择检查类型"
                  value-key="dictKey"
                  clearable
                  @change="checkTypeObj"
                >
                  <el-option
                    v-for="item in safey_check_type"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
             <el-col :span="8">
              <el-form-item label="执行周期" prop="cycleFrequency">
                <el-input
                  v-model="dataForm.cycleFrequency"
                  placeholder="请填写执行周期"
                  @focus="cycleShowDialog"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否启用" prop="enableFlag">
                <el-switch
                  v-model="dataForm.enableFlag"
                  active-value="Y"
                  inactive-value="N"
                ></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="开始日期" prop="startDate">
                <el-date-picker
                  v-model="dataForm.startDate"
                  type="date"
                  placeholder="选择开始日期"
                  clearable
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="结束日期" prop="endDate">
                <el-date-picker
                  v-model="dataForm.endDate"
                  type="date"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="pickerOptions"
                  placeholder="选择结束日期"
                  clearable
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="检查记录" prop="remark">
                <el-input
                  type="textarea"
                  v-model="dataForm.remark"
                  placeholder="请填写检查记录"
                  maxlength="500"
                  show-word-limit
                  :rows="4"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="shuttleBackBox">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              label="检查组组长"
              name="thirdPartyOrganization"
            ></el-tab-pane>
            <el-tab-pane label="参与人员" name="checkJoinTab"></el-tab-pane>
            <el-tab-pane label="检查项" name="corePersonnel"></el-tab-pane>
            <el-tab-pane
              label="相关资料 "
              name="thirdPartyPersonnel"
            ></el-tab-pane>
          </el-tabs>

          <el-container class="main-box" v-if="activeName == 'corePersonnel'">
            <CommonTree
              treeTitle="检查项分类"
              ref="commonTreeCore"
              :isShowdig="formType != 'view' && dataForm.id"
              @getTreeAdd="getTreeAdd"
              @getTreeDelete="getTreeDelete"
              @getTreeEdit="getTreeEdit"
              :isLeafIcons="true"
              :iconsFlied="'isObject'"
              :searchTitle="jcxSearchTitle"
              :treeData="corePersonnelTreeData"
              :defaultProps="defaultProps"
              @getNodeClick="corePersonnelTreeNodeClick"
              :showCheckbox="false"
              :treeExpand="true"
               node-key="id"
            />
            <div class="table-box">
              <head-layout
                :head-btn-options="
                  formType != 'view' && dataForm.id
                    ? corePersonnelHeadBtnOptions
                    : []
                "
                @head-fx="headFx"
                @handleImport="handleImport"
                @head-export="handleExport"
                @head-remove="headDel"
                @head-add="addjcx"
                head-title="检查项"
              ></head-layout>
              <grid-layout
                ref="jcxPage"
                :table-options="corePersonnelTableOption"
                :data-total="page.total"
                :page="page"
                @page-current-change="getJcx"
                @page-size-change="getJcx"
                :table-data="corePersonnelTableData"
                :table-loading="jcxTableLoading"
                @gird-handle-select-click="selectionChange"
              >
                <template #customBtn="{ row }" v-if="formType != 'view'">
                  <el-button
                    style="margin: 0 3px"
                    type="text"
                    size="small"
                    @click="addjcx(row)"
                    >编辑</el-button
                  >
                  <el-button
                    style="margin: 0 3px"
                    type="text"
                    size="small"
                    @click="rowDel([row])"
                    >删除</el-button
                  >
                </template>
              </grid-layout>
            </div>
          </el-container>
          <div
            v-if="activeName == 'thirdPartyOrganization'"
            class="thirdPartyOrganization"
          >
            <head-layout
              head-title="检查组组长"
              :head-btn-options="
                formType != 'view'  ? userBtnOptions : []
              "
              @head-add="headAdd('jcry')"
              @head-remove="headRemove"
            ></head-layout>
            <grid-layout
              ref="jcryPage"
              :table-options="thirdPartyOrganizationTableOption"
              :table-data="thirdPartyOrganizationTableData"
              :table-loading="tableLoading"
              :page="jcryPage"
              :total="jcryPage.total"
              @page-current-change="getJcUser"
              @page-size-change="getJcUser"
              @gird-handle-select-click="perosnselectionChange"
            >
              <template #customBtn="{ row }" v-if="formType != 'view'">
                <el-button
                  style="margin: 0 3px"
                  type="text"
                  size="small"
                  @click="personRowDel([row],'jcry')"
                  >删除</el-button
                >
              </template>
            </grid-layout>
          </div>

          <div
            v-if="activeName == 'checkJoinTab'"
            class="checkJoinTab"
          >
            <head-layout
              head-title="参与人员"
              :head-btn-options="
                formType != 'view'  ? cyryBtnOptions : []
              "
              @head-add="headAdd1('cyry')"
              @head-remove="headRemove"
            ></head-layout>
            <grid-layout
              ref="cyryPage"
              :table-options="checkJoinDataOption"
              :table-data="checkJoinData"
              :table-loading="checkJoinDataLoading"
              :page="cyryPage"
              :total="cyryPage.total"
              @page-current-change="getJcUser"
              @page-size-change="getJcUser"
              @gird-handle-select-click="perosnselectionChange"
            >
              <template #customBtn="{ row }" v-if="formType != 'view'">
                <el-button
                  style="margin: 0 3px"
                  type="text"
                  size="small"
                  @click="personRowDel([row],'cyry')"
                  >删除</el-button
                >
              </template>
            </grid-layout>
          </div>

          <div v-if="activeName == 'thirdPartyPersonnel'">
            <head-layout
              head-title="资料清单"
              :head-btn-options="dataForm.id ? fileBtnOptions : []"
              @headImport="headImport"
            >
              <el-button
                size="mini"
                slot="right"
                v-if="formType != 'view' && dataForm.id"
                @click="quote"
                >知识库选择</el-button
              >
            </head-layout>
            <el-upload
              :disabled="formType == 'view'"
              :on-preview="handlePreview"
              :file-list="xgzlFileList"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              class="upload-demo"
            >
            </el-upload>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="检查项"
      v-dialogDrag
      v-if="jcxModel"
      :visible.sync="jcxModel"
      width="40%"
    >
      <avue-form
        :option="jcxOption"
        @reset-change="resetChange1"
        @submit="handleSubmit1"
        v-model="jcxForm"
      ></avue-form>
    </el-dialog>
    <!-- <el-dialog
      title="检查项分类"
      v-if="jcxflModel"
      v-dialogDrag
      :visible.sync="jcxflModel"
      width="40%"
    >
      <avue-form
        :option="jcxflOption"
        @reset-change="resetChange"
        @submit="handleSubmit"
        v-model="jcxflForm"
        v-if="jcxflModel"
      ></avue-form>
    </el-dialog> -->
    <el-dialog
      title="检查对象及分类新增"
      v-if="jcxflModel"
      v-dialogDrag
      :visible.sync="jcxflModel"
      width="50%"
    >
      <avue-form
        :option="jcxflOption"
        @reset-change="resetChange"
        @submit="handleSubmit"
        v-model="jcxflForm"
      >
        <template #typeName>
          <el-input
            v-model="jcxflForm.typeName"
            v-if="
              jcxflForm.objectType == 'SD' ||
              jcxflForm.id ||
              jcxflForm.isObject == 0
            "
          ></el-input>
          <el-button
            type="primary"
            @click="handleTable('EQ')"
            v-if="
              jcxflForm.objectType == 'EQ' &&
              !jcxflForm.id &&
              !jcxflForm.isObject == 0
            "
            >选择设备设施</el-button
          >
          <el-button
            type="primary"
            v-if="
              jcxflForm.objectType == 'AREA' &&
              !jcxflForm.id &&
              !jcxflForm.isObject == 0
            "
            @click="handleTable('qy')"
            >选择区域</el-button
          >
          <el-button
            type="primary"
            v-if="
              jcxflForm.objectType == 'WORK' &&
              !jcxflForm.id &&
              !jcxflForm.isObject == 0
            "
            @click="handleTable('ts')"
            >选择特殊作业</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
    <el-upload
      v-show="hideUpload"
      class="upload-demo"
      action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km"
      :show-file-list="false"
      :on-success="handleSuccess"
      ref="uploadRef"
      :headers="headers"
    ></el-upload>
    <el-dialog title="人员选择" :visible.sync="deptShow" width="80%" top="8vh">
      <!-- <UserDetpDialog
        ref="UserDetpDialog"
        :dept-category="[2, 5]"
        @select-data="selectData"
      ></UserDetpDialog> -->
      <ProjectSelectUser @closeDia="deptShow=false" :treeParams="{parentId: dataForm.organizationId}" v-if="deptShow" @select-data="getUser"></ProjectSelectUser>
    </el-dialog>
    <el-dialog
      title="人员选择"
      :visible.sync="MultipShow"
      width="80%"
      top="8vh"
      v-if="MultipShow"
    >
      <!-- <UserDeptMultipleDialog
        @closeDia="MultipShow = false"
        ref="UserDeptMultipleDialog"
        @select-all-data="selectData"
      ></UserDeptMultipleDialog> -->
      <ProjectSelectUser @closeDia="MultipShow=false" v-if="MultipShow" :treeParams="{parentId: dataForm.organizationId}" @select-data="getUser" multiple></ProjectSelectUser>
    </el-dialog>
    <el-dialog title="项目选择" :visible.sync="deptShow1" width="80%">
      <DeptDialog ref="DeptDialog" @select-data="selectData1"></DeptDialog>
    </el-dialog>
    <el-dialog
      title="风险库及措施"
      v-if="fxyModel"
      v-dialogDrag
      :visible.sync="fxyModel"
      width="80%"
      class="dangerBox"
    >
      <el-container style="height: 500px">
        <CommonTree
          treeTitle="风险源"
          :defaultProps="{
            children: 'children',
            id: 'id',
            label: 'sourceTypeName',
          }"
          :searchTitle="searchTitle"
          :showCheckbox="false"
          :treeData="treeData"
          :treeExpand="true"
          style="font-size: 13px"
          @getNodeClick="handleNodeClick"
        />
        <el-main>
          <!--                    <grid-head-layout-->
          <!--                      :searchSpan="2"-->
          <!--                      ref="searchForm"-->
          <!--                      :search-columns="searchColumns"-->
          <!--                      @grid-head-search="searchChange"-->
          <!--                      @grid-head-empty="searchReset"-->
          <!--                    />-->
          <div class="searchTopBox">
            <div class="leftSearch">
              <div style="width: 100%; display: flex; margin-bottom: 6px">
                <el-input
                  v-model="searchForm.riskName"
                  size="mini"
                  placeholder="请输入风险名称"
                  class="searchBox"
                ></el-input>
                <el-input
                  v-model="searchForm.treatmentTypeName"
                  size="mini"
                  placeholder="请输入措施分类"
                  class="searchBox"
                ></el-input>
                <el-input
                  v-model="searchForm.treatmentDesc"
                  size="mini"
                  placeholder="请输入措施描述"
                  class="searchBox"
                ></el-input>
                <el-input
                  v-model="searchForm.hdCheckContext"
                  size="mini"
                  placeholder="请输入隐患排查内容"
                  class="searchBox"
                ></el-input>
                <el-select
                  v-model="searchForm.controlLevel"
                  placeholder="请输入管控层级"
                  size="mini"
                  class="searchBox"
                >
                  <el-option
                    v-for="item in riskControlLevel"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictKey"
                  >
                  </el-option>
                </el-select>
              </div>
              <div style="width: 100%; display: flex; margin-bottom: 6px">
                <!-- <el-input
                  v-model="searchForm.respPostName"
                  size="mini"
                  placeholder="请输入岗位/角色"
                  class="searchBox"
                ></el-input> -->
                <el-input
                  v-model="searchForm.respUserName"
                  size="mini"
                  placeholder="请输入负责人员"
                  class="searchBox"
                ></el-input>
                <div class="rightSearch">
                  <el-button-group>
                    <el-button
                      size="mini"
                      type="primary"
                      icon="el-icon-search"
                      @click="searchChange"
                    ></el-button>
                    <el-button
                      size="mini"
                      icon="reset-refresh icon-refresh"
                      @click="searchReset"
                      style="height: 28px"
                    ></el-button>
                  </el-button-group>
                </div>
              </div>
            </div>
          </div>
          <grid-layout
            ref="gridLayOut"
            :table-options="fxyOption"
            :data-total="fxxPage.total"
            :page="fxxPage"
            :table-data="fxyTableData"
            :table-loading="fxyTableLoading"
            @gird-handle-select-click="selectionChange"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
          >
          </grid-layout>
        </el-main>
      </el-container>
      <div class="avue-dialog__footer">
        <el-button @click="fxyModel = false">取 消</el-button>
        <el-button @click="handleFxySave" type="primary">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="周期配置"
      :visible.sync="cycleShow"
      v-if="cycleShow"
      width="30%"
    >
      <el-form
        :model="cycleForm"
        label-width="100px"
        :rules="cycleRules"
        ref="cycleForm"
      >
        <!-- <el-form-item label="类型" prop="durationType">
          <el-radio-group
            v-model="cycleForm.durationType"
            @change="durationTypeChange"
          >
            <el-radio
              v-for="item in cycleDicData"
              :key="item.dictKey"
              :label="item.dictKey"
              >{{ item.dictValue }}</el-radio
            >
          </el-radio-group>
        </el-form-item> -->
        <el-form-item
          label="CRON表达式"
          v-if="cycleForm.durationType == 'CRON'"
          prop="cronStr"
        >
          <el-input
            v-model="cycleForm.cronStr"
            placeholder="请输入CRON表达式"
          ></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="时间间隔"
              prop="planInterval"
              v-if="cycleForm.durationType == 'NORMAL'"
            >
              <el-input-number
                v-model="cycleForm.planInterval"
                label="时间间隔"
                min="0"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="单位"
              prop="intervalUnit"
              v-if="cycleForm.durationType == 'NORMAL'"
            >
              <el-select
                v-model="cycleForm.intervalUnit"
                placeholder="请选择单位"
                @change="intervalUnitChange"
              >
                <el-option
                  v-for="item in unitData"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="选择周"
              prop="startWeek"
              v-if="cycleForm.intervalUnit == 'week'"
            >
              <el-select v-model="cycleForm.startWeek" placeholder="请选择周几">
                <el-option
                  v-for="item in weekMap"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item
              label="选择月"
              prop="startMonth"
              v-if="cycleForm.intervalUnit == 'month'"
            >
              <el-select
                v-model="cycleForm.startMonth"
                placeholder="请选择月份"
                @change="monthChange"
              >
                <el-option
                  v-for="i in 12"
                  :label="i"
                  :value="i"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="选择日"
              prop="startDay"
              v-if="cycleForm.intervalUnit == 'month' && cycleForm.startMonth"
            >
              <el-select v-model="cycleForm.startDay" placeholder="请选择日">
                <el-option
                  v-for="i in nowDays"
                  :label="i"
                  :value="i"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="选择日期"
              prop="cycleTime"
              v-if="cycleForm.intervalUnit == 'year'"
            >
              <el-date-picker
                v-model="cycleForm.cycleTime"
                type="date"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- <el-form-item
              label="起始日期"
              prop="startTime"
              v-if="cycleForm.durationType == 'NORMAL'"
            >
              <el-time-select
                v-model="cycleForm.startTime"
                :picker-options="{
                  start: '00:00',
                  step: '00:01',
                  end: '23:59',
                }"
                placeholder="选择时分"
              >
              </el-time-select>
            </el-form-item> -->
            <el-form-item
              label="起始(小时)"
              prop="startTimeh"
              v-if="cycleForm.durationType == 'NORMAL'"
            >
              <el-select v-model="cycleForm.startTimeh" placeholder="请选择">
                <el-option
                  v-for="item in hoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="起始(分)"
              prop="startTimem"
              v-if="cycleForm.durationType == 'NORMAL'"
            >
              <el-select v-model="cycleForm.startTimem" placeholder="请选择">
                <el-option
                  v-for="item in moptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button
          size="small"
          @click="cycleShow = false"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleCycleFormSave"
          v-loading="treeDialogLoading"
        >
          保存
        </el-button>
      </span>
    </el-dialog>

    <!-- 引用检查活动 -->
    <el-dialog
      title="引用检查活动"
      v-if="quoteModel"
      v-dialogDrag
      :visible.sync="quoteModel"
      width="80%"
    >
      <div style="width: 100%; height: 520px">
        <grid-head-layout
          ref="searchForm"
          :search-columns="QuoteSearchColumns"
          @grid-head-search="searchChangeQuote"
          @grid-head-empty="searchResetQuote"
        />
        <grid-layout
          ref="gridLayOut"
          :table-options="hdOption"
          :data-total="hdtableTotal"
          :table-data="hdTableData"
          :table-loading="hdTableLoading"
          @gird-handle-select-click="selectionChangeQuote"
          :page="pageQuote"
          @page-current-change="getQuotes"
          @page-size-change="getQuotes"
        >
          <template slot="checkTypeName" slot-scope="{ row }">
            <div>{{ getCheckTypeName(row.checkType) }}</div>
          </template>
          <template #customBtn="{ row }">
            <el-button
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="selectQuote(row)"
              >选择</el-button
            >
          </template>
        </grid-layout>
      </div>
      <div class="avue-dialog__footer">
        <el-button @click="quoteModel = false">取 消</el-button>
        <!-- <el-button @click="hanldeQuoteSave" type="primary">确 定</el-button> -->
      </div>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
    <WorkTicket
      ref="WorkTicket"
      v-if="WorkTicketShow"
      @selectWorkTicket="selectWorkTicket"
    ></WorkTicket>
    <riskArea ref="riskArea" multiple @callback="selectWorkTicket"></riskArea>
    <el-dialog
      top="9vh"
      title="知识库选择"
      :visible.sync="showKnowledgeSearchDialog"
      width="80%"
    >
      <KnowledgeSearchDialog
        ref="knowledgeSearchDialog"
        @close-dialog="closeKnowledgeSearchDialog"
        @select-data="selectFile"
      ></KnowledgeSearchDialog>
    </el-dialog>
    <equipmentDialog ref="equipmentDialog" @callback="selectWorkTicket" />
    <el-dialog
      title="检查项导入"
      v-if="excelBox"
      append-to-body
      :visible.sync="excelBox"
      width="555px"
    >
      <div class="import">
        <avue-form
          :option="excelOption"
          v-model="excelOption.excelForm"
          :upload-after="uploadAfter"
        >
          <template slot="excelTemplate">
            <el-button type="primary" @click="handleTemplate">
              {{ $t("cip.plat.sys.user.field.download")
              }}<i class="el-icon-download el-icon--right"></i>
            </el-button>
          </template>
        </avue-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import mixins from "./mixins";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";
import { getToken } from "@/util/auth";
import { exportBlob } from "@/api/common";
import { downloadXls } from "@/util/util";
import { dateNow } from "@/util/date";
import { getDictionaryCode } from "@/api/system/dictbiz";
import { dictionaryBiz } from "@/api/reportTasks";
import { mapGetters } from "vuex";

import * as API from "@/api/check/troubleshootingPlan";
import { getTree } from "@/api/riskManage/manage";
import * as project from "@/api/riskManage/project";
import { getList } from "@/api/check/daily";
import website from "@/config/website";
import UserDeptMultipleDialog from "@/views/components/UserDeptDialog/UserDeptMultipleDialog.vue";
import WorkTicket from "@/components/WorkTicket/index.vue";
import riskArea from "@/views/hiddenTrouble/check/daily/region.vue";
import KnowledgeSearchDialog from "@/views/components/knowledge/KnowledgeSearchDialog";
import equipmentDialog from "@/views/equipmentFacilities/equipmentMaintenance/compoment/equipmentDialog.vue";
import ProjectSelectUser from "@/views/components/UserDeptDialog/projectSelectUser";

export default {
  mixins: [mixins],
  components: {
    UserDetpDialog,
    equipmentDialog,
    UserDeptMultipleDialog,
    WorkTicket,
    riskArea,
    CommonTree,
    GridLayout,
    KnowledgeSearchDialog,
    HeadLayout,
    ProjectSelectUser
  },
  data() {
    return {
      dialogType: "",
      showKnowledgeSearchDialog: false,
      WorkTicketShow: false,
      searchForm: {},
      knowledgeBaseShow: false,
      fileUrl: "",
      showFileDialog: false,
      MultipShow: false,
      quoteModel: false,
      pickerOptions: {
        disabledDate(time) {
          return new Date().getTime() - 24 * 60 * 60 * 1000 >= time.getTime();
        },
      },
      weekMap: [
        { label: "周一", value: 1 },
        { label: "周二", value: 2 },
        { label: "周三", value: 3 },
        { label: "周四", value: 4 },
        { label: "周五", value: 5 },
        { label: "周六", value: 6 },
        { label: "周天", value: 7 },
      ],
      hoptions: [],
      moptions: [],
      formType: "add",
      rules: {
        planCode: [
          { required: true, message: "请输入配置编号", trigger: "input" },
        ],
        planName: [
          { required: true, message: "请输入配置名称", trigger: "input" },
        ],
        organizationName: [
          { required: true, message: "请选择关联项目", trigger: "change" },
        ],
         checkLeaderName: [
          {
            required: true,
            message: "请选择检查组组长",
            trigger: "change",
          },
        ],
        checkJoinName: [
          {
            required: false,
            message: "请选择参与人员",
            trigger: "change",
          },
        ],
        checkType: [
          { required: true, message: "请选择检查类型", trigger: "blur" },
        ],
        cycleFrequency: [
          { required: true, message: "请配置执行周期", trigger: "input" },
        ],
        startDate: [
          { required: true, message: "请选择开始日期", trigger: "change" },
        ],
        endDate: [
          { required: true, message: "请选择结束日期", trigger: "change" },
        ],
      },
      cycleRules: {
        durationType: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
        planInterval: [
          { required: true, message: "请输入时间间隔", trigger: "blur" },
        ],
        intervalUnit: [
          { required: true, message: "请选择单位", trigger: "change" },
        ],
        startTimeh: [
          { required: true, message: "请选择起始时间(小时)", trigger: "blur" },
        ],
        startTimem: [
          { required: true, message: "请选择起始时间(分)", trigger: "blur" },
        ],
        cycleTime: [{ required: true, message: "请选择日期", trigger: "blur" }],
        startMonth: [{ required: true, message: "请选择月", trigger: "blur" }],
        startDay: [{ required: true, message: "请选择日", trigger: "blur" }],
        startWeek: [{ required: true, message: "请选择周", trigger: "blur" }],
        cronStr: [{ required: true, message: "请输入表达式", trigger: "blur" }],
      },
      cycleShow: false,
      companydefaultProps: {
        children: "children",
        id: "id",
        label: "title",
      },
      cycleForm: {
        durationType: "NORMAL",
        intervalUnit: "day",
        startTimeh: "",
        startTimem: "",
        startDay: "",
        startYear: "",
        startMonth: "",
        startWeek: "",
        cycleTime: "",
      },
      cycleDicData: [],
      projectSelection: [],
      jcxflForm: {
        parentId: -1,
        objectName: "",
        typeName: "",
        isObject: 0,
      },
      dataForm: {
        enableFlag: "N",
        startDate: "",
      },
      prjCodeAuto: true,
      fileList: [
        {
          name: "food.jpeg",
          url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        },
        {
          name: "food2.jpeg",
          url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        },
      ],
      deptShow: false,
      hideUpload: false,
      unitData: [],
      dicCodeMap: {
        HD_CHECK_PLAN_DURATION_TYPE: "cycleDicData",
        interval_unit: "unitData",
      },
      durationMap: {
        快速配置: "NORMAL",
        CRON表达式: "CRON",
      },
      tableTotal: 0,
      hdtableTotal: 0,
      isSource: false,
      fxyTableLoading: false,
      hdTableLoading: false,
      treeData: [],
      fxyTableData: [],
      hdTableData: [],
      nowTreeNode: {},
      corePersonnelTreeData: [],
      jcxForm: {},
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      fxxPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      pageQuote: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      fxyPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectTree: undefined,
      safey_check_type: [],
      punishmentList: [],
      RectifyList: [],
      xgzlFileList: [],
      fxyModel: false,
      jcxModel: false,
      jcxflModel: false,
      deptShow1: false,
      type: "",
      excelBox: false,
      projectInfo: {},
      checkTypeItem: {},
      quoteSelectionList: [],
      searchTitle: "sourceTypeName",
      jcxSearchTitle: "typeName",
      personselectionList: [],
      riskControlLevel: [],
      nowDays: 31,
      modelType: false,
      corePersonnelTreeTypes: [],

      editJcxfl: false,
      workPermitType: [],
      checkePersonObj: {}
    };
  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    personids() {
      let ids = [];
      this.personselectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    ...mapGetters(["userInfo"]),
  },
  async mounted() {
    this.hoptions = this.createOptions(24);
    this.moptions = this.createOptions(60);
    this.dataForm.startDate = this.formatDate(new Date());
    await this.getDictionaryCode("HD_CHECK_PLAN_DURATION_TYPE");
    await this.getDictionaryCode("interval_unit");
    // await this.getDetTree(this.userInfo.dept_id);
    dictionaryBiz("safey_check_type").then((res) => {
      this.safey_check_type = res.data.data;
    });
    dictionaryBiz("risk_control_level").then((res) => {
      this.riskControlLevel = res.data.data;
    });
     dictionaryBiz("work_permit_type").then((res) => {
      this.workPermitType = res.data.data;
    });
    let type = this.$route.query.type;
    this.formType = type;
    if (type == "edit") {
      this.headTitle = "项目配置";
      this.dataForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
      API.getCheckPersonList({
        planId: this.dataForm.id || "-1",
        current: this.jcryPage.currentPage,
        size: this.jcryPage.pageSize,
        personType: 'leader',
      }).then((res) => {
        this.thirdPartyOrganizationTableData = res.data.data.records || [];
        this.jcryPage.total = res.data.data.total;
      });
    } else if (type == "view") {
      this.headTitle = "查看项目配置";
      this.dataForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
      API.getCheckPersonList({
        planId: this.dataForm.id || "-1",
        current: this.jcryPage.currentPage,
        size: this.jcryPage.pageSize,
        personType: 'leader',
      }).then((res) => {
        this.thirdPartyOrganizationTableData = res.data.data.records || [];
        this.jcryPage.total = res.data.data.total;
      });
    } else {
      this.$set(this.dataForm, "organizationId", this.$route.query.organizationId);
      this.$set(this.dataForm, "organizationName", this.$route.query.organizationName);
      // this.dataForm.projectId = this.userInfo.dept_id;
      // this.dataForm.projectName = this.userInfo.dept_name;
      this.getCode();
      this.$set(this.dataForm, "createUser", this.userInfo.user_id);
      this.$set(this.dataForm, "createUserName", this.userInfo.real_name);
      this.$set(this.dataForm, "createDept", this.userInfo.dept_id);
    }
    this.excelOption.column[0].action = `/api/sinoma-hse-prj/hdcheckplanindex/import?planId=${this.dataForm.id}`;
  },
  methods: {
    createOptions(sum) {
      let arr = [];
      for (let i = 0; i < sum; i++) {
        arr.push({
          label: i < 10 ? `0${i}` : `${i}`,
          value: i < 10 ? `0${i}` : `${i}`,
        });
      }
      return arr;
    },
    handleSizeChange(e) {
      this.fxyPage.pageSize = e.pageSize;
      this.searchChange();
    },
    handleCurrentChange(e) {
      this.fxyPage.currentPage = e.currentPage;
      this.searchChange();
    },
    selectFile(data) {
      console.log(data, "selectFile");

      let file = data.map((item) => {
        let file = item.attachList[0] || {};
        return {
          planId: this.dataForm.id,
          name: item.fileName,
          extension: item.extension || item.fileSuffixType,
          originalName: item.name || item.fileName,
          link: file.link || item.fileCover,
        };
      });
      console.log(file, "selectFile");
      this.addFileList(file);
      this.showKnowledgeSearchDialog = false;
    },
    closeKnowledgeSearchDialog() {
      this.showKnowledgeSearchDialog = false;
    },

    handleTable(type) {
      this.modelType = type;
      if (type == "ts") {
        this.WorkTicketShow = true;
        this.$nextTick(() => {
          this.$refs.WorkTicket.ticketBox = true;
        });
      } else if (type == "qy") {
        this.$refs.riskArea.init();
      } else if (type == "EQ") {
        this.$refs.equipmentDialog.init();
      }
    },
    selectWorkTicket(data) {
      console.log(data);
      let newData = !Array.isArray(data) ? [data] : data;
      let saveList = newData.map((item) => {
        let objectName = "";
        if (item.ticketType) {
          let ticketName = this.workPermitType.find(i=>i.dictKey==item.ticketType).dictValue
          objectName = `${ticketName}-${item.content.substring(0, 10)}`;
        } else {
          objectName = item.name || item.areaName || item.equipmentName;
        }
        return {
          // ...item,
          targetId: this.modelType == "EQ" ? item.id : "",
          typeCode:
            this.modelType == "EQ"
              ? item.equipmentCode
              : this.modelType == "qy"
              ? item.areaCode
              : "",
          objectType: this.jcxflForm.objectType,
          typeName: objectName,
          parentId: this.jcxflForm.parentId || -1,
          typeId: this.jcxflForm.parentId || -1,
          planId: this.dataForm.id,
          // stTypeId: this.modelType == "EQ" ? item.standardTypeId : "",
          stTypeId: this.modelType == "EQ" ? item.typeId : "", // 20240925：设备类型废弃、设备分类与标准库对齐

        };
      });
      API.hdcheckindexSaveList(saveList).then((res) => {
        this.jcxflModel = false;
        this.$message.success(res.data.msg);
        this.typeTree();
      });
    },
    monthChange() {
      let date = new Date(
        new Date().getFullYear(),
        this.cycleForm.startMonth,
        0
      );
      // 返回天数
      this.nowDays = date.getDate();
    },
    async getJcUser(val) {
      console.log(val);
      this.jcryPage = val || this.jcryPage;
      this.jcryTableLoading = true;
      await API.getCheckPersonList({
        planId: this.dataForm.id,
        current: this.jcryPage.currentPage,
        size: this.jcryPage.pageSize,
        personType: 'leader',
      }).then((res) => {
        this.thirdPartyOrganizationTableData = res.data.data.records;
        this.jcryPage.total = res.data.data.total;
        this.jcryTableLoading = false;
      });
    },
    formatDate(date) {
      function padZero(num) {
        return num < 10 ? "0" + num : num;
      }

      var year = date.getFullYear();
      var month = padZero(date.getMonth() + 1);
      var day = padZero(date.getDate());
      var hours = padZero(date.getHours());
      var minutes = padZero(date.getMinutes());
      var seconds = padZero(date.getSeconds());

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    getCheckTypeName(key) {
      return this.safey_check_type.find((item) => {
        return item.dictKey == key;
      }).dictValue;
    },
    quote() {
      // this.$refs.knowledgeBase.initModal();
      this.showKnowledgeSearchDialog = true;
    },
    // 获取引用的数据源
    getQuotes(page, params) {
      let pageQuote = this.pageQuote;
      pageQuote.currentPage = page.currentPage;
      pageQuote.pageSize = page.pageSize;
      this.hdtableLoading = true;
      getList(pageQuote.currentPage, pageQuote.pageSize, {
        ...params,
        organizationId: this.userInfo.dept_id,
        noPrepare: 1,
      }).then((res) => {
        if (res.data.code == 200) {
          this.hdtableLoading = false;
          this.hdTableData = res.data.data.records;
          this.pageQuote.total = res.data.data.total;
        }
      });
    },
    handleCycleFormSave() {
      this.$refs.cycleForm.validate((valid) => {
        if (valid) {
          this.cycleShow = false;
          this.cycleForm.startTime = `${this.cycleForm.startTimeh}:${this.cycleForm.startTimem}`;
          if (this.cycleForm.durationType == "CRON") {
            // this.dataForm.cycleFrequency = this.cycleForm.cronStr;
            this.$set(this.dataForm, "cycleFrequency", this.cycleForm.cronStr);
          } else {
            if (this.cycleForm.intervalUnit == "year") {
              let mapArr = this.cycleForm.cycleTime.split("-");
              this.cycleForm.startYear = mapArr[0];
              this.cycleForm.startMonth = String(Number(mapArr[1]));
              this.cycleForm.startDay = String(Number(mapArr[2]));
            }
            let unitName =
              this.unitData.find((item) => {
                return item.dictKey == this.cycleForm.intervalUnit;
              }).dictValue || "";
            // this.dataForm.cycleFrequency =
            //   this.cycleForm.planInterval + unitName;
            this.$set(
              this.dataForm,
              "cycleFrequency",
              this.cycleForm.planInterval + unitName
            );
          }
        }
      });
    },
    cycleShowDialog() {
      this.cycleShow = true;
    },
    getCode() {
      API.getCode({}).then((res) => {
        this.$set(this.dataForm, "planCode", res.data.data || "");
      });
    },
    // 自动生成编码
    prjCodeAutoChange() {
      if (this.prjCodeAuto) {
        this.getCode();
      } else {
        this.dataForm.planCode = "";
      }
    },

    // 获取上级公司id
    getParentDet(id = "") {
      project
        .getParentDet({
          id: id || this.userInfo.dept_id,
        })
        .then((res) => {
          this.getDetTree(res.data.data.parentId);
        });
    },
    // 获取项目树
    getDetTree(parentId) {
      project.getDetTree({ categorys: "2,5", parentId }).then((res) => {
        let projectSelection = res.data.data || [];
        if (projectSelection.length != 0) {
          this.projectSelection = projectSelection;
        }
      });
    },
    handleNodeClickListTree(node, obj) {
      this.dataForm.projectName = node["title"];
      this.dataForm.projectId = node["id"];
      this.defaultExpandedKey = [];
      this.$refs.selectTree.blur();
    },
    clearHandle() {
      this.dataForm.projectName = "";
      this.dataForm.projectId = "";
      this.defaultExpandedKey = [];
      this.clearSelected();
    },
    clearSelected() {
      let allNode = document.querySelectorAll("#tree-option .el-tree-node");
      allNode.forEach((element) => element.classList.remove("is-current"));
    },
    // 获取字典
    getDictionaryCode(code) {
      getDictionaryCode(code).then((res) => {
        this[this.dicCodeMap[code]] = res.data.data;
      });
    },
    //导入
    handleImport() {
      // if (this.selectTree.isObject == 0) {
      //   this.$message({
      //     message: "请选择检查对象",
      //     type: "warning",
      //   });
      //   return;
      // }
      this.excelBox = true;
      this.excelOption.excelForm.excelFile = [];
    },
    uploadAfter(res, done, loading, column) {
      this.excelBox = false;
      this.typeTree()
      this.excelOption.excelForm.excelFile = [];
      done();
    },
    handleTemplate() {
      exportBlob(
        `/api/sinoma-hse-prj/hdcheckplanindex/exportTemplate?planId=${this.dataForm.id}`
      ).then((res) => {
        downloadXls(res.data, "检查项" + ".xls");
      });
    },
    // 导出
    handleExport() {
      if (!this.dataForm.id){
         return this.$message.success("基础信息未保存，请先保存基础信息");
      }
      this.$confirm("是否导出检查项数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        exportBlob(
          `/api/sinoma-hse-prj/hdcheckplanindex/export?typeId=${this.selectTree?this.selectTree.id:''}&planId=${this.dataForm.id}`
        ).then((res) => {
          downloadXls(
            res.data,
            this.selectTree.typeName
          );
        });
      });
    },

    headImport() {
      if (
        this.formType == "view" ||
        (this.formType != "view" && !this.dataForm.id)
      ) {
        return;
      }
      this.$refs["uploadRef"].$children[0].$refs.input.click();
    },

    //批量选择
    selectionChange(list) {
      this.selectionList = list;
    },
    perosnselectionChange(list) {
      this.personselectionList = list;
    },
    selectionChangeQuote(list) {
      this.quoteSelectionList = list;
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // projectChange() {
    //   if (this.projectInfo.deptCategory == 2) {
    //     this.dataForm.organizationId = this.projectInfo.id;
    //     this.dataForm.organizationName = this.projectInfo.title || "";
    //   } else {
    //     this.dataForm.projectId = this.projectInfo.id;
    //     this.dataForm.projectName = this.projectInfo.title;
    //   }
    // },
    checkTypeObj() {
      console.log(this.dataForm, "checkTypeObj");
      this.$set(this.dataForm, "checkType", this.checkTypeItem.dictKey);
      this.$set(this.dataForm, "checkTypeName", this.checkTypeItem.dictValue);
      // this.dataForm.checkType = this.checkTypeItem.dictKey;
      // this.dataForm.checkTypeName = this.checkTypeItem.dictValue;
    },
    // 引用
    headQuote() {
      this.quoteModel = true;
      this.getQuotes(this.pageQuote, {});
    },
    // 保存
    headSave() {
      if (
        this.dataForm.startDate &&
        this.dataForm.endDate &&
        this.dataForm.startDate >= this.dataForm.endDate
      ) {
        this.$message({
          message: "开始日期不能大于或等于结束日期",
          type: "warning",
        });
        return;
      }
      if (
        !this.thirdPartyOrganizationTableData.length &&
        this.dataForm.enableFlag == "Y"
      ) {
        this.$message({
          message: "未添加检查人员不能启用该计划",
          type: "warning",
        });
        return;
      }
      if(!this.thirdPartyOrganizationTableData.length){
              return this.$message({
                message: "请添加检查组组长数据",
                type: "warning",
              })
      }
      this.$refs.dataForm.validate((valid) => {
        console.log(valid);
        if (valid) {
          let hdCheckObj = {}
          this.$loading();
          if (!this.dataForm.id) {
            hdCheckObj = {
              hdCheckPlanPersons: this.thirdPartyOrganizationTableData,
              hdCheckPlanJoinPersons: this.checkJoinData
            }
          }
          API.submitList({ ...this.dataForm, ...this.cycleForm,...hdCheckObj })
            .then((res) => {
              this.dataForm.id = res.data.data;
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.excelOption.column[0].action = `/api/sinoma-hse-prj/hdcheckplanindex/import?planId=${this.dataForm.id}`;
              this.$set(this, "activeName", "thirdPartyOrganization");
              this.handleClick();
              this.$loading().close();
            })
            .catch((error) => {
              this.$loading().close();
            });
        }
      });
    },
    // 根据ID查表单详情
    async getPrjbasicinfoDetail() {
      await API.getListDetail({ id: this.dataForm.id }).then((res) => {
        this.dataForm = res.data.data;
        this.$set(
          this.dataForm,
          "organizationName",
          this.dataForm.organizationName
        );
        this.$set(this.dataForm, "startDate", this.dataForm.startDate);
        this.$set(this.dataForm, "endDate", this.dataForm.endDate);
        this.$set(this.checkTypeItem, "dictKey", this.dataForm.checkType);
        this.$set(this.checkTypeItem, "dictValue", this.dataForm.checkTypeName);
        this.$set(this.cycleForm, "durationType", this.dataForm.durationType);
        this.$set(this.cycleForm, "intervalUnit", this.dataForm.intervalUnit);
        this.$set(this.cycleForm, "planInterval", this.dataForm.planInterval);
        this.$set(this.cycleForm, "cronStr", this.dataForm.cronStr);
        // this.$set(this.cycleForm, "startTime", this.dataForm.startTime);
        this.$set(
          this.cycleForm,
          "startTimeh",
          this.dataForm.startTime.split(":")[0]
        );
        this.$set(
          this.cycleForm,
          "startTimem",
          this.dataForm.startTime.split(":")[1]
        );
        this.$set(this.cycleForm, "startDay", this.dataForm.startDay);
        this.$set(this.cycleForm, "startYear", this.dataForm.startYear);
        this.$set(this.cycleForm, "startMonth", this.dataForm.startMonth);
        this.$set(this.cycleForm, "startWeek", this.dataForm.startWeek);
        this.$set(this.cycleForm, "cycleTime", this.dataForm.cycleTime);
        this.typeTree();
        console.log(this.dataForm, "dataFormdataFormdataFormdataFormdataForm");
      });
    },
    handleClick() {
      if (this.activeName == "corePersonnel") {
        this.typeTree();
       API.getCheckPersonList({
          current: 1,
          size: 9999,
          planId: this.dataForm.id || "-1",
          personType: 'leader',
        }).then((res) => {
          this.checkePersonObj = res.data.data.records.length?res.data.data.records[0]:{}
        });
      } else if (this.activeName == "thirdPartyOrganization") {
        if(!this.dataForm.id){
          this.$nextTick(()=>{
           this.$refs.jcryPage.$refs.grid.$refs.table.doLayout();
          })
          return
        }
        API.getCheckPersonList({
          current: this.jcryPage.currentPage,
          size: this.jcryPage.pageSize,
          planId: this.dataForm.id || "-1",
          personType: 'leader',
        }).then((res) => {
          this.thirdPartyOrganizationTableData = res.data.data.records || [];
          this.$nextTick(()=>{
           this.$refs.jcryPage.$refs.grid.$refs.table.doLayout();
          })
          this.jcryPage.total = res.data.data.total;
          this.jcryTableLoading = false;
        });
      } else if (this.activeName == "checkJoinTab") {
        if(!this.dataForm.id){
          this.$nextTick(()=>{
            this.$refs.cyryPage.$refs.grid.$refs.table.doLayout();
          })
          return
        }
        API.getCheckPersonList({
          current: this.jcryPage.currentPage,
          size: this.jcryPage.pageSize,
          planId: this.dataForm.id || "-1",
          personType: 'joiner',
        }).then((res) => {
          this.checkJoinData = res.data.data.records || [];
          this.$nextTick(()=>{
            this.$refs.cyryPage.$refs.grid.$refs.table.doLayout();
          })
          this.cyryPage.total = res.data.data.total;
          this.cyryTableLoading = false;
        });
      } else if (this.activeName == "thirdPartyPersonnel") {
        this.getFile();
      }
    },
    selectData1(row) {
      this.deptShow1 = false;
      this.$set(this.dataForm, "projectId", row.id);
      this.$set(this.dataForm, "projectName", row.deptName);
    },
    selectData(row) {
      if (this.type == "respUserName") {
        this.$set(this.jcxForm, "respUserName", row.account);
        this.$set(this.jcxForm, "respNickName", row.realName);
      } else if (this.type == "jcry") {
        console.log(row);
        if (!row.selectAllData.length) {
          return;
        }
        let newRow = row.selectAllData.map((item) => {
          return {
            deptCode: item.code,
            roleName: item.roleName,
            roleCode: item.roleCode,
            respPostName: item.respPostName,
            nickName: item.nickName,
            deptId: row.treeDeptId,
            deptName: row.treeDeptName,
            userId: item.id,
            userName: item.account,
            planId: this.dataForm.id,
          };
        });
        API.submitCheckPersonList(newRow).then((res) => {
          this.$message.success(res.data.msg);
          this.handleClick();
        });
      }
      this.deptShow = false;
      this.MultipShow = false;
    },
    getUser(data){
      console.log('选择项目下的人',data)
      if (this.type == "respUserName") {
        this.$set(this.jcxForm, "respUserName", row.account);
        this.$set(this.jcxForm, "respNickName", row.realName);
      } else if (this.type == "jcry") {
        // console.log(row);
        // if (!row.selectAllData.length) {
        //   return;
        // }
        // let newRow = row.selectAllData.map((item) => {
        //   return {
        //     deptCode: item.code,
        //     roleName: item.roleName,
        //     roleCode: item.roleCode,
        //     respPostName: item.respPostName,
        //     nickName: item.nickName,
        //     deptId: row.treeDeptId,
        //     deptName: row.treeDeptName,
        //     userId: item.id,
        //     userName: item.account,
        //     planId: this.dataForm.id,
        //   };
        // });
        // API.submitCheckPersonList(newRow).then((res) => {
        //   this.$message.success(res.data.msg);
        //   this.handleClick();
        // });
         let newRow = []
        let obj = {
          ...data,
           attr1: data.stPostName,
           userId: data.userId,
           userName: data.account,
           nickName:data.nickName ||data.userNickName || data.userName,
           personType: 'leader',
           id: ''
        }
        if (this.dataForm.id) {
          obj.planId = this.dataForm.id;
        }
        newRow.push(obj)
        if (!this.dataForm.id) {
          this.thirdPartyOrganizationTableData = newRow;
          this.deptShow = false;
          this.MultipShow = false;
          return;
        }
        API.submitCheckPersonList(newRow).then((res) => {
          this.$message.success(res.data.msg);
          this.handleClick();
        });
      } else if (this.type == "checkLeaderName") {
        if(this.dataForm.checkLeaderId){
         
           if(!this.dataForm.id){
            this.thirdPartyOrganizationTableData.splice(this.thirdPartyOrganizationTableData.findIndex(el=>el.userId==this.dataForm.checkLeaderId),1)
          }
          if(this.dataForm.id&&this.thirdPartyOrganizationTableData.findIndex(item=>item.userId==this.dataForm.checkLeaderId)>-1){
            let formData = new FormData();
            let ids = this.thirdPartyOrganizationTableData.find(item=>item.userId==this.dataForm.checkLeaderId).id
            formData.append("ids", ids);
            API.hdcheckpersonremove(formData).then(res=>{
              this.userList = [];
              this.getJcUser();
            })
          }
        }
        
       this.$set(this.dataForm, "checkLeaderId", data.userId);
       this.$set(this.dataForm, "checkLeaderName", data.userName);

        let newRow = []
        let obj = {
          ...data,
           attr1: data.stPostName,
           userId: data.userId,
           userName: data.account,
           nickName:data.nickName ||data.userNickName || data.userName,
        }
        if (this.dataForm.id) {
          obj.planId = this.dataForm.id;
        }
        newRow.push(obj)

        if (!this.dataForm.id) {
          let tempArr = this.thirdPartyOrganizationTableData.concat(newRow);
          const uniqueArr = tempArr.reduce((acc, cur) => {
            const hasDuplicate = acc.some(item => item.userId === cur.userId);
            if (!hasDuplicate) {
              acc.push(cur);
            }
            return acc;
          }, []);
          this.thirdPartyOrganizationTableData = uniqueArr;
          this.deptShow = false;
          this.MultipShow = false;
          return;
        }
        
        API.hdcheckpersonSubmit(newRow).then((res) => {
          this.$message.success(res.data.msg);
          this.handleClick();
        });
      } else if (this.type == "checkJoinName") {
        this.$set(
          this.dataForm,
          "checkJoinId",
          data.map((item) => item.userId).join(",")
        );
        this.$set(
          this.dataForm,
          "checkJoinName",
          data.map((item) => item.userName).join(",")
        );
      } else if (this.type=="cyry"){
         let newRow = data.map((item) => {
          let obj = {
          ...item,
           attr1: item.stPostName,
           userId: item.userId,
           userName: item.account,
           nickName:item.nickName ||data.userNickName || item.userName,
           personType: 'joiner',
           id: ''
          }
          if (this.dataForm.id) {
            obj.planId = this.dataForm.id;
          }
          return obj;
        });
        if (!this.dataForm.id) {
          let tempArr = this.checkJoinData.concat(newRow);
          const uniqueArr = tempArr.reduce((acc, cur) => {
            const hasDuplicate = acc.some(item => item.userId === cur.userId);
            if (!hasDuplicate) {
              acc.push(cur);
            }
            return acc;
          }, []);
          this.checkJoinData = uniqueArr;
          console.log(this.checkJoinData)
          return;
        }
        API.submitCheckPersonList(newRow).then((res) => {
          this.$message.success(res.data.msg);
          this.handleClick();
        });
      }
      this.deptShow = false;
      this.MultipShow = false;
    },
    handleProjectOPen() {
      this.deptShow1 = true;
    },
    headAdd(type) {
      this.type = type;
      this.deptShow = true;
    },
    headAdd1(type) {
      this.type = type;
      this.MultipShow = true;
    },
    headRemove() {
      this.personRowDel(this.personselectionList);
    },
    corePersonnelTreeNodeClick(row) {
      this.selectTree = row;
      // if (this.selectTree.isObject == 1) {
      this.getJcx();
      // }
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 删除上传文件
    handleRemove(file, fileList) {
      let list = new Array();
      fileList.forEach((i) => {
        if (i.response) {
          list.push({
            name: i.name,
            url: i.response.data.link,
          });
        } else {
          list.push({
            name: i.name,
            url: i.url,
          });
        }
      });
      API.removeInformationList({ ids: file.id }).then((res) => {
        this.$message.success("移除成功");
        this.getFile();
      });
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    addFile(list) {
      API.submitInformation(...list).then((res) => {
        this.$message.success(res.data.msg);
        this.getFile();
      });
    },
    addFileList(list) {
      API.submitInformationList(list).then((res) => {
        this.$message.success(res.data.msg);
        this.getFile();
      });
    },
    getFile() {
      if (this.dataForm.id) {
        API.getInformationList({
          planId: this.dataForm.id || "-1",
        }).then((res) => {
          this.xgzlFileList = res.data.data;
        });
      }
    },
    // 新增上传文件
    handleSuccess(response, file, fileList) {
      let { name, link, originalName } = response.data;
      this.addFile([
        {
          planId: this.dataForm.id,
          name: originalName,
          link: link,
          originalName: name,
        },
      ]);
    },
    handleFxySave() {
      let selectionList = this.selectionList.map((item) => {
        return {
          ...item,
          respNickName: item.respUserName,
          respUserName: item.respUserAccount,
          id: "",
          indexName: item.hdCheckContext,
          // indexTypeId: this.selectTree.id,
          // indexTypeName: this.selectTree.typeName,
          objectId: this.selectTree.id,
          objectName: this.selectTree.typeName,
          planId: this.dataForm.id,
        };
      });
      API.hdcheckindexsaveList(selectionList).then((res) => {
        this.fxyModel = false;
        this.selectionList = [];
        this.$message.success(res.data.msg);
        this.getJcx();
      });
    },
    headFx() {
      if (!this.selectTree)
        return this.$message.warning("请选择检查对象或检查分类");
      this.fxyModel = true;
      this.searchReset();
      this.getdemotree();
    },
    getNodeId(clo, id) {
      if (id) {
        const result = this.treeArrData.find((item) => item.id == id);
        this.isSource = result.isSource;
        this.nowTreeNode = result || {};
      } else if (clo && clo.value) {
        const result = this.treeArrData.find((item) => item.id == clo.value);
        this.isSource = result.isSource || false;
        this.nowTreeNode = result || {};
      }
    },
    intervalUnitChange() {
      this.cycleForm.startTime = "";
      this.cycleForm.startTimeh = "";
      this.cycleForm.startTimem = "";
      this.cycleForm.startDay = "";
      this.cycleForm.startYear = "";
      this.cycleForm.startMonth = "";
      this.cycleForm.startWeek = "";
      this.cycleForm.cycleTime = "";
    },
    searchReset() {
      this.searchForm = {};
      this.fxyPage.currentPage = 1;
      this.searchChange();
    },
    searchResetQuote() {
      this.searchChangeQuote({});
    },
    searchChangeQuote(query = {}) {
      if (query.checkTimes && query.checkTimes.length == 2) {
        query.startTime = query.checkTimes[0] + " 00:00:00";
        query.endTime = query.checkTimes[1] + " 23:59:59";
        delete query.checkTimes;
      }
      if (!query.checkType) {
        query.checkType = null;
      }

      let pageQuote = this.pageQuote;
      pageQuote.currentPage = 1;
      pageQuote.pageSize = 20;
      this.getQuotes(this.pageQuote, query);
    },
    searchChange() {
      let params = {
        ...this.searchForm,
        size: this.fxyPage.pageSize,
        current: this.fxyPage.currentPage,
        organizationId: this.userInfo.dept_id,
      };
      if (this.isSource) {
        params.sourceId = this.nowNodeId;
      } else {
        params.sourceTypeId = this.nowNodeId;
      }
      // 调用列表接口;
      this.fxyTableLoading = true;
      API.pmriskTreatment(params)
        .then((res) => {
          this.fxyTableData = res.data.data.records;
          this.fxxPage.total = res.data.data.total;
          this.$refs.gridLayOutfxy.page.total = res.data.data.total;
          this.fxyTableLoading = false;
        })
        .catch((error) => {
          this.fxyTableLoading = false;
        });
    },

    //点击树节点显示列表
    handleNodeClick(data) {
      this.nowNodeId = data.id;
      this.getNodeId({}, this.nowNodeId);
      this.fxyPage.currentPage = 1;
      this.searchChange();
    },
    //扁平化 树
    flattenTree(tree, parent = null) {
      let result = [];
      for (let node of tree) {
        let flatNode = { ...node, parent };
        result.push(flatNode);
        if (node.children && node.children.length) {
          result = result.concat(this.flattenTree(node.children, flatNode));
        }
        delete flatNode.children;
      }
      return result;
    },
    //树
    getdemotree() {
      project
        .getTree({
          organizationId: this.userInfo.dept_id,
        })
        .then((res) => {
          if (res.status == 200) {
            this.treeData = res.data.data;
            this.treeArrData = this.flattenTree(res.data.data);
          }
        });
    },
    typeTree() {
      API.getTree({
        planId: this.dataForm.id || "-1",
      }).then((res) => {
        this.corePersonnelTreeData = res.data.data;
        if (res.data.data.length != 0) {
          this.selectTree = res.data.data[0];
          this.$nextTick(() => {
            this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
              this.selectTree.id
            );
            this.$refs.commonTreeCore.nodeData = this.selectTree;
            this.corePersonnelTreeNodeClick(this.selectTree);
          });
        }
      });
    },
    getTypeTree() {
      API.getTreeTypes({
        planId: this.dataForm.id || "-1",
      }).then((res) => {
        this.corePersonnelTreeTypes = res.data.data;
      });
    },
    resetChange() {
      this.jcxflModel = false;
      this.jcxflForm = {};
    },
    resetChange1() {
      this.jcxModel = false;
      this.jcxForm = {};
    },
    handleSubmit1() {
      API.submitTreeList({
        ...this.jcxForm,
        planId: this.dataForm.id,
      }).then((res) => {
        this.jcxModel = false;
        this.$message.success(res.data.msg);
        this.getJcx();
        this.resetChange1();
      });
    },
    durationTypeChange() {
      this.cycleForm = {
        durationType: this.cycleForm.durationType,
      };
      if (this.cycleForm.durationType == "NORMAL") {
        this.cycleForm.intervalUnit = "hour";
      }
      this.cycleForm = {
        ...this.cycleForm,
        startTime: "",
        startTimeh: "",
        startTimem: "",
        startDay: "",
        startYear: "",
        startMonth: "",
        startWeek: "",
        cycleTime: "",
      };
    },
    handleSubmit(form, done) {
      let data = {
        ...this.jcxflForm,
        typeName: this.jcxflForm.typeName,
        planId: this.dataForm.id,
      };
      // console.log(this.jcxflForm, "handleSubmit");
      // return;
      let url = API.submitTree;
      // this.jcxflForm.isObject == 0
      //   ? API.submitTree
      //   : this.editJcxfl
      //   ? API.hdcheckindexObjUpdate
      //   : API.hdcheckindexSaveList;
      if (url == API.hdcheckindexSaveList) {
        data = [data];
      }
      url(data)
        .then((res) => {
          this.jcxflModel = false;
          this.$message.success(res.data.msg);
          this.typeTree();
        })
        .catch((err) => {
          done();
        });
    },
    // handleSubmit() {
    //   API.submitTree({
    //     ...this.jcxflForm,
    //     planId: this.dataForm.id,
    //   }).then((res) => {
    //     this.jcxflModel = false;
    //     this.$message.success(res.data.msg);
    //     this.typeTree();
    //   });
    // },
    getJcx(page) {
      if (page) {
        this.page = page;
      }
      this.jcxTableLoading = true;
      API.getTreeList({
        current: this.page.currentPage,
        size: this.page.pageSize,
        planId: this.dataForm.id || "-1",
        // indexTypeId: this.selectTree.id,
        // indexTypeName: this.selectTree.typeName,
        objectId: this.selectTree.id,
        objectName: this.selectTree.typeName,
      }).then((res) => {
        this.page.total = res.data.data.total;
        this.corePersonnelTableData = res.data.data.records;
        this.jcxTableLoading = false;
      });
    },
    addjcx(row = {}) {
     if(!this.corePersonnelTreeData.length){
        return this.$message.success("请选择检查项分类或对象");
      }

      if(!Object.keys(this.checkePersonObj).length){
        return this.$message.success("请配置检查项组长");
      }
       
      // if (this.selectTree.isObject == 0)
      //   return this.$message.warning("请选择检查对象");
      
      
      this.jcxForm = {
        ...row,
        // indexTypeId: this.selectTree.id,
        // indexTypeName: this.selectTree.typeName,
        objectId: this.selectTree.id,
        objectName: this.selectTree.typeName,
      };
      if(!Object.keys(row).length){
        this.jcxForm.respUserName = this.checkePersonObj.userName
        this.jcxForm.respNickName = this.checkePersonObj.nickName||this.checkePersonObj.userNickName
        this.jcxForm.respUserAccount = this.checkePersonObj.userAccount
      }
      this.jcxModel = true;
    },
    headDel() {
      this.rowDel(this.selectionList,'cyry');
    },
    personRowDel(data,delTab) {
      this.personselectionList = data;
      if (this.personselectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      let formData = new FormData();
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
           if(!this.dataForm.id){
            data.forEach((item,index)=>{
              if(delTab=='jcry'){
                this.thirdPartyOrganizationTableData.splice(this.thirdPartyOrganizationTableData.findIndex(el=>el.userId==item.userId),1)
              }else{
                this.checkJoinData.splice(this.checkJoinData.findIndex(el=>el.userId==item.userId),1)
              }
            })
             this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
            return
          }
          formData.append("ids", this.personids);
          return API.removeCheckPersonList(formData).then(()=>{
            this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
          this.handleClick();
          });
          })
    },
    rowDel(data) {
      this.selectionList = data;
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      let formData = new FormData();
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          formData.append("ids", this.ids);
          return API.removeTreeList(formData);
        })
        .then(() => {
          this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
          this.getJcx();
        });
    },
    getTreeAdd(row) {
      this.editJcxfl = false;
      // this.getTypeTree();
      if (row) {
        this.jcxflForm = {
          parentName: row.typeName,
          parentId: row.id || -1,
          typeId: row.id,
          isObject: row.isObject,
        };
        this.jcxflModel = true;
      } else if (!this.selectTree) {
        this.jcxflForm = {
          parentId: -1,
          objectName: "",
          typeName: "",
          isObject: 0,
        };
        this.jcxflModel = true;
      } else {
        this.$message.warning("请选择检查项分类");
        this.jcxflModel = false;
      }
    },
    getTreeDelete(data) {
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),

        type: "warning",
      })
        .then(() => {
          // if (data.isObject == 1) {
          //   return API.hdcheckindexRemoveList({ ids: data.id });
          // }
          return API.removeTree({ ids: data.id });
        })
        .then(() => {
          this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
          this.typeTree();
          this.selectTree = undefined;
        });
    },
    getTreeEdit(row) {
      this.editJcxfl = true;
      this.jcxflForm = {
        ...row,
        parentName: row.parentName,
        parentId: row.parentId,
      };
      console.log(this.jcxflForm);
      if (!this.selectTree) {
        this.jcxflForm = {};
      }
      this.$set(this.jcxflForm, "isObject", row.isObject);
      // this.getTypeTree();
      this.jcxflModel = true;
    },
    selectQuote(row) {
      let obj = {
        actId: row.id,
        deptId: this.userInfo.dept_id,
        deptName: this.userInfo.dept_name,
      };
      if (this.dataForm.id) {
        obj.planId = this.dataForm.id;
      }
      API.quoteInfo(obj).then((res) => {
        this.dataForm = res.data.data;
        this.checkTypeItem = {
          dictKey: this.dataForm.checkType,
          dictValue: this.dataForm.checkTypeName,
        };
        this.$set(this.dataForm, "id", this.dataForm.id);
        this.excelOption.column[0].action = `/api/sinoma-hse-prj/hdcheckplanindex/import?planId=${this.dataForm.id}`;
        this.getPrjbasicinfoDetail();
        this.jcxflForm = res.data.data.hdCheckPlanIndexTypes || [];
        this.jcxForm = res.data.data.hdCheckPlanIndexs || [];
        this.thirdPartyOrganizationTableData =
          res.data.data.hdCheckPlanPersons || [];
        this.xgzlFileList = res.data.data.hdCheckPlanMaterials || [];
        this.$message.success("引用成功");
        this.quoteModel = false;
      });
    },
    hanldeQuoteSave() {
      if (this.quoteSelectionList.length > 1) {
        this.$message.warning("只能选择一条数据进行引用");
        return;
      }
      let obj = {
        actId: this.quoteSelectionList[0].id,
        deptId: this.userInfo.dept_id,
        deptName: this.userInfo.dept_name,
      };
      if (this.dataForm.id) {
        obj.planId = this.dataForm.id;
      }
      API.quoteInfo(obj).then((res) => {
        this.dataForm = res.data.data;
        this.$set(this.dataForm, "id", this.dataForm.id);
        this.getPrjbasicinfoDetail();
        this.jcxflForm = res.data.data.hdCheckPlanIndexTypes || [];
        this.jcxForm = res.data.data.hdCheckPlanIndexs || [];
        this.thirdPartyOrganizationTableData =
          res.data.data.hdCheckPlanPersons || [];
        this.xgzlFileList = res.data.data.hdCheckPlanMaterials || [];
        this.$message.success("引用成功");
        this.quoteModel = false;
      });
      // 确定
    },
    showFile(item) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(item.link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    handlePreview(item) {
      console.log(item, "handlePreview");
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(item.link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .common_tree_handle {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
::v-deep .avue-crud .el-table {
  height: 400px !important;
  max-height: 400px !important;
}

.shuttleBackBox {
  ::v-deep .avue-crud .el-table {
    height: calc(100vh - 520px) !important;
    max-height: calc(100vh - 520px) !important;
  }
  .table-box {
    ::v-deep .avue-crud .el-table {
      height: calc(100vh - 572px) !important;
      max-height: calc(100vh - 572px) !important;
    }
  }
}
.shuttleBackBox {
  .thirdPartyOrganization {
    ::v-deep .avue-crud .el-table {
      height: calc(100vh - 572px) !important;
      max-height: calc(100vh - 572px) !important;
    }
  }
}

.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}

.formMain {
  width: 100%;
  height: 100%;
}

.formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}

::v-deep .shuttleBackBox {
  width: 100%;
  height: calc(100% - 225px);
  .main-box {
    height: calc(100% - 56px);
  }
  .el-tabs {
    width: 100%;
  }

  .upload-demo {
    background-color: #ffffff;
    padding-left: 10px;
  }

  .table-box {
    height: 100%;
    overflow: hidden;
    width: calc(100% - 280px);
    // #gridLayout {
    //   height: calc(100% - 50px) !important;
    // }

    // .avue-crud {
    //   height: 100% !important;
    //   .el-card__body {
    //     height: 100% !important;
    //     .el-form {
    //       height: 100% !important;
    //       .el-table {
    //         height: 100% !important;
    //       }
    //     }
    //   }
    // }
  }
}

.shutleBack {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.leftRightBtn {
  margin: 211px 12px;
}

.shutleTitle {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}

.shutContent {
  padding: 6px;
  height: 400px;
  overflow: auto;
}

.deptBox {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: flex;
}

.unitBox {
  width: 100%;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}

.unitTitle {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}
.searchTopBox {
  padding: 14px 12px;
  width: calc(100% - 24px);
  display: flex;
  justify-content: space-between;
}

.leftSearch {
  width: 100%;
}

.searchBox {
  width: calc(20% - 5px) !important;
  margin-right: 5px;
}
.common_tree_handle .el-tree {
  height: 100% !important;
}
</style>
